import React, { useEffect, useState } from "react";
import Loading from "../../components/loading/loading";
import { useAuthentication } from "../../hooks/auth/useLogin";
import { useAllFarmData } from "../../hooks/farm/useAllFarmData";
import FarmLogin from "./farmLogin";
import MyRewards from "./myRewards";
import FarmOverview from "./farmOverview";
import SingleAssetPools from "./pools/singleAssetPools";
import SushiPools from "./pools/sushiPools";
import AlbumPools from "./pools/albumPools";

const FarmContainer = () => {
  const { isLoggedIn, user, error, authLoading } = useAuthentication();
  const [allFarmData, dataLoading, dataError] = useAllFarmData();

  //Loading profile ...
  if (authLoading || dataLoading) {
    return (
      <div className="flex flex-col h-full place-content-center">
        <div className="mt-16 mx-auto">
          <Loading size={32} />
        </div>
      </div>
    );
  }

  return (
    <div className="h-full space-y-32 lg:space-y-48 p-32">
      {!isLoggedIn && <FarmLogin />}
      {user && <MyRewards address={user.uid} />}
      <FarmOverview
        tvl={allFarmData.tvl}
        distributedRewards={allFarmData.distributedRewards}
        totalRewards={allFarmData.totalRewards}
      />
      <SingleAssetPools
        isLoggedIn={isLoggedIn}
        singlePools={allFarmData.singleAsset}
      />
      <SushiPools isLoggedIn={isLoggedIn} sushiPools={allFarmData.liquidity} />
      {/* <AlbumPools isLoggedIn={isLoggedIn} /> */}
    </div>
  );
};

export default FarmContainer;
