import React, { FC } from "react";
import Loading from "../../components/loading/loading";
import { useGetMyRewards } from "../../hooks/farm/useGetMyRewards";

interface MyRewardsProps {
  address: string;
}

const MyRewards: FC<MyRewardsProps> = ({ address }) => {
  const { myRewards, loading, error } = useGetMyRewards(address);

  return (
    <div className="flex flex-col w-full">
      <h2 className="pb-24 text-dark-white-64">My rewards</h2>
      <div className="lg:flex lg:flex-row">
        <div className="flex lg:w-1/3">
          <div className="text-dark-light-grey my-24 w-full">
            <div className="flex flex-row w-full pb-4">
              <p className="caption flex flex-grow">Current Reward</p>
            </div>
            {loading ? (
              <p className="display1 pb-16 text-gradient animate-pulse">
                0 SZNS
              </p>
            ) : (
              <p className="display1 pb-16 text-gradient fade-in">
                {myRewards.rewards.toLocaleString()} SZNS
              </p>
            )}
          </div>
        </div>
        <div className="flex">
          <div className="text-dark-light-grey my-24 w-full">
            <div className="flex flex-row w-full pb-4">
              <p className="caption flex flex-grow">SZNS Balance</p>
            </div>
            {loading ? (
              <p className="display1 text-gradient animate-pulse">0 SZNS</p>
            ) : (
              <p className="display1 text-gradient fade-in">
                {myRewards.balance.toLocaleString()} SZNS
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRewards;
