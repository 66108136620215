import React, { FC } from "react";
import PoolCard from "./poolCard";
import { Pool } from "../../../models/pools";

interface SushiPoolsProps {
  isLoggedIn: boolean;
  sushiPools: Pool[];
}

const SushiPools: FC<SushiPoolsProps> = ({ isLoggedIn, sushiPools }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row w-full justify-between">
        <h2 className="pb-24 text-dark-white-64">Sushi pools</h2>
        <div>
          <button
            className="bg-transparent text-dark-red text-left btn-second rounded-md hover:opacity-80 active:opacity-75 focus:ring-transparent focus:outline-none"
            onClick={() => {}}
          >
            Learn more
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xlg:grid-cols-4 gap-16 xlg:gap-24">
        {sushiPools.map((pool) => (
          <PoolCard pool={pool} key={pool.token} isLoggedIn={isLoggedIn} />
        ))}
      </div>
    </div>
  );
};

export default SushiPools;
