import React, { FC } from "react";
import { Pool } from "../../../models/pools";
import Button from "../../common/button/button";
import { getPoolImg, getPoolColor } from "../../../utils/farm/utils";
import { navigate } from "gatsby";

interface PoolCardProps {
  pool: Pool;
  isLoggedIn: boolean;
}

const PoolCard: FC<PoolCardProps> = ({ pool, isLoggedIn }) => {
  // TODO: LINEAR(SZN-706) Map ICON & COLOR on Front-End based on POOL Token

  return (
    <div
      className="flex flex-col bg-black px-20 py-24"
      style={{
        borderRadius: "8px",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div className="space-y-24 text-primary-dark-grey">
        <div className="flex flex-row items-center">
          <img src={getPoolImg(pool.name)} className="w-48 h-48 rounded-full" />
          <div
            className="flex flex-col ml-16"
            style={{ color: getPoolColor(pool.name) }}
          >
            <h2>{pool.name}</h2>
            {pool.type !== "sushi_liquidity" ? null : (
              <p className="body1">{pool.name.replace("/", "_")}_SUSHI_LP</p>
            )}
          </div>
        </div>
        <div className="flex flex-row text-dark-light-grey">
          <p className="body1 flex flex-grow">APR</p>
          <div className="flex flex-col">
            <h2 className="text-right">
              {parseFloat(pool.apr.toFixed(2)).toLocaleString()}%
            </h2>
            <p className="caption text-dark-white-64">
              {parseFloat(pool.poolRewardsPerDay.toFixed(2)).toLocaleString()}{" "}
              SZNS/DAY on 1 {pool.name}
            </p>
          </div>
        </div>
        <div className="flex flex-row text-dark-light-grey">
          <p className="body1 flex flex-grow">Reward</p>
          <h2>{pool.poolReward.toLocaleString()}</h2>
        </div>
        <div className="flex flex-row text-dark-light-grey">
          <p className="body1 flex flex-grow">Pool Balance</p>
          <h2 className="ml-16 break-all">
            ${pool.poolBalance.toLocaleString()}
          </h2>
        </div>
        <div className="flex flex-row">
          <Button
            fill={false}
            width="full"
            onClick={() => navigate(`/farm/${pool.id}`)}
            disabled={!isLoggedIn}
          >
            Stake
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PoolCard;
