import { navigate } from "gatsby-link";
import React, { useEffect } from "react";
import FarmContainer from "../components/farm/farmContainer";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useFeature } from "../hooks/feature/useFeature";

const FarmPage = () => {
  const [farmFeatureEnabled, farmFeatureLoading, farmFeatureError] =
    useFeature("farm");

  useEffect(() => {
    if (!farmFeatureEnabled) {
      navigate("/explore");
    }
  }, []);

  return (
    <Layout>
      <SEO title="Farm" />
      <div className="h-full flex flex-col w-full">
        <div className="flex-grow min-h-0">
          <div className="flex flex-col min-h-0 h-full max-h-full">
            <FarmContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FarmPage;
