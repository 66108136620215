import WBTC from "../../images/farm/WBTC.jpg";
import AAVE from "../../images/farm/AAVE.jpg";
import AXS from "../../images/farm/AXS.jpg";
import ETH from "../../images/farm/ETH.jpg";
import SUSHI from "../../images/farm/SUSHI.jpg";
import USDC from "../../images/farm/USDC.jpg";
import SZNS from "../../images/icons/szns/szns_logo_icon_full-color.png";

// takes in a user object and returns a username if available or formatted wallet address
export const getPoolImg = (tokenName: string) => {
  let formattedTokenName = tokenName.toUpperCase().split("/")[0];

  switch (formattedTokenName) {
    case "WBTC":
      return WBTC;
    case "AAVE":
      return AAVE;
    case "AXS":
      return AXS;
    case "ETH":
      return ETH;
    case "SUSHI":
      return SUSHI;
    case "USDC":
      return USDC;
    default:
      return SZNS;
  }
};

export const getPoolColor = (tokenName) => {
  let formattedTokenName = tokenName.toUpperCase().split("/")[0];

  switch (tokenName) {
    case "WBTC":
      return "#EF9242";
    case "AAVE":
      return "#BE4CA4";
    case "AXS":
      return "#018DED";
    case "ETH":
      return "#627EEA";
    case "SUSHI":
      return "#E35CA7";
    case "USDC":
      return "#0075D1";
    default:
      return "#8fd0e9";
  }
};
