import React from "react";
import { navigate } from "gatsby";
import Login from "../wallet/login";

const FarmLogin = () => {
  return (
    <div className="flex flex-col w-full">
      <div
        className="flex flex-col w-full md:w-1/2 lg:w-1/3 xlg:w-1/4 bg-black"
        style={{
          borderRadius: "8px",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="text-white px-24 space-y-16 my-24">
          <p className="caption">WELCOME</p>
          <p className="body1">Connect wallet to start farming</p>
          <Login size="md" width="full" />
        </div>
      </div>
    </div>
  );
};

export default FarmLogin;
