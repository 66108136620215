import React, { FC } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { useAlbumTokenData } from "../../hooks/album/useAlbumTokenData";
import Hint from "../common/hint/hint";
import { CONTRACT_ADDRS } from "../../constants/contractAddrs";

interface FarmOverviewProps {
  tvl: number;
  distributedRewards: number;
  totalRewards: number;
}

const FarmOverview: FC<FarmOverviewProps> = ({
  tvl,
  distributedRewards,
  totalRewards,
}) => {
  // TODO: Update this call with SZNS Token Address
  const [tokenData, loadingTokenData] = useAlbumTokenData("0xSZNSTokenAddress");

  return (
    <div className="flex flex-col w-full">
      <h2 className="pb-24 text-dark-white-64">SZNS Farm Overview</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
        <div
          className="flex bg-black"
          style={{
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="px-24 space-y-16 my-24 w-full">
            <div className="flex flex-row w-full text-dark-white-64">
              <p className="caption flex flex-grow">Total Value Locked</p>
              <Hint title="The amount of assets currently being staked in the SZNS Farm">
                <InfoIcon className="cursor-pointer" />
              </Hint>
            </div>
            <h2 className="pb-4 text-white">
              ${parseFloat(tvl.toFixed(2)).toLocaleString()}
            </h2>
          </div>
        </div>
        <div
          className="flex bg-black"
          style={{
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="px-24 my-24 w-full">
            <div className="flex flex-row w-full text-dark-white-64 pb-16">
              <p className="caption flex flex-grow">SZNS Price</p>
              <Hint title="The current price of the SZNS token, tradeable on SushiSwap">
                <InfoIcon className="cursor-pointer" />
              </Hint>
            </div>
            {/* TODO: update with SUSHI call */}
            {loadingTokenData ? (
              <h2 className="pb-4 text-white animate-pulse">$-.--</h2>
            ) : (
              <h2 className="pb-4 text-white fade-in">
                {!tokenData ? "N/A" : `$${tokenData.priceUSD.toFixed(2)}`}
              </h2>
            )}
            <div>
              <a
                href={`https://app.sushi.com/swap?outputCurrency=${CONTRACT_ADDRS.SZNS_TOKEN}`}
                target="_blank"
              >
                <button className="bg-transparent text-dark-red text-left btn-primary rounded-md hover:opacity-80 active:opacity-75 focus:ring-transparent focus:outline-none">
                  Trade on SushiSwap
                </button>
              </a>
            </div>
          </div>
        </div>
        <div
          className="flex bg-black"
          style={{
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="px-24 my-24 w-full">
            <div className="flex flex-row w-full text-dark-white-64 pb-16">
              <p className="caption flex flex-grow">SZNS Rewards</p>
              <Hint title="The amount of SZNS tokens distributed as Farming rewards so far">
                <InfoIcon className="cursor-pointer" />
              </Hint>
            </div>
            <h2 className="pb-4 text-white">
              {distributedRewards.toLocaleString()} SZNS
            </h2>
            <p className="body2 text-dark-white-64">
              out of {totalRewards.toLocaleString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarmOverview;
