import { useEffect, useState } from "react";

import SushiSwapService from "../../services/sushiswap";

export const useAlbumTokenData = (tokenAddr: string): any => {
  const [token, setToken] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const sushi = new SushiSwapService();
    sushi
      .getTokenData(tokenAddr)
      .then((tokenData) => {
        setToken(tokenData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return [token, loading];
};
